module.exports = [{
      plugin: require('../plugins/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/buildhome/repo/src/intl","languages":["it"],"defaultLanguage":"it","redirect":false,"useYaml":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Battistutta Microfusioni","short_name":"Battistutta Microfusioni","start_url":"./","background_color":"#333","theme_color":"#ffffff","display":"standalone","icon":"src/assets/img/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6f5f6b678004546624c4f6c1f69ea213"},
    }]
