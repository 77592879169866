import React from 'react';
import Helmet from 'react-helmet';

import '../stylesheets/main.less';

import Footer from '../components/footer';

if (typeof window !== `undefined`) {
  require(`smooth-scroll`)(`a[href*="#"]`, { updateURL: false });
}

class Layout extends React.Component {
  render() {
    const { children, pageContext } = this.props;
    const { language } = pageContext.intl;

    return (
      <>
        <Helmet>
          <html lang={language} />
        </Helmet>

        <div className="page">
          {children}

          <Footer />
        </div>
      </>
    );
  }
}

export default Layout;
