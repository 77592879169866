import React from 'react';

import privateData from '../../../data.json';

const Footer = () => (
  <footer>
    <p>Copyright © 2020 - Battistutta Microfusioni - P.IVA 02037090061</p>
    <a
      href={privateData.iubenda.privacyPolicyRef}
      className="iubenda-black iubenda-embed"
      title="Privacy Policy"
    >
      Privacy Policy
    </a>
  </footer>
);

export default Footer;
